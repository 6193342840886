(function() {
  'use strict';

  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_SMALL_TABLET_WIDTH = 900;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.DESKTOP_WIDE_WIDTH = 1366;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header-wrapper');
  window.$HEADER_INFO_MENU = $('.header-info-menu-container > .wrapper > ul');
  window.$HEADER_SERVICE_MENU = $('.header-service-menu-container');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  $WINDOW
    .on('calcSizes', () => {
      window.VIEWPORT_WIDTH = document.documentElement.clientWidth || Math.round(window.visualViewport.width) || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER.height();
    })
    .on('resize', () => {
      $WINDOW.trigger('calcSizes');
    })
    .trigger('calcSizes');

  window.IS_MOBILE = /\b(Android|BlackBerry|IEMobile|iP(hone|ad|od)|Opera Mini|webOS|Windows Phone)\b/i.test(navigator.userAgent)/* && !window.MSStream*/;
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDE_WIDTH = () => VIEWPORT_WIDTH > DESKTOP_WIDE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = () => VIEWPORT_WIDTH >= DESKTOP_WIDTH;
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = () => (VIEWPORT_WIDTH >= PORTRAIT_TABLET_WIDTH && VIEWPORT_WIDTH < DESKTOP_WIDTH);
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = () => VIEWPORT_WIDTH < PORTRAIT_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_SMALL_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_SMALL_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = () => VIEWPORT_WIDTH < SMALL_MOBILE_WIDTH;



  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');


  $WINDOW
    .one('load', () => {
      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', function() {
          this.checked ? $BODY.addClass('menu-is-opened') : $BODY.removeClass('menu-is-opened');
        })
        .prop('checked', false)
        .prop('disabled', false)
        .trigger('change');

      $DOCUMENT.trigger('headerEvents');
    })
    .on('resize', () => {
      $DOCUMENT.trigger('headerEvents');
    });


  $DOCUMENT
    // Scroll to
    // ---------
    .on('click.js-scroll-to', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substring($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = $lnk.data('scrollOffset') || 0;

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Callback content row show
    // -------------------------
    .on('click.callback-content-link', '.callback-form .form-row-content-link span', function(e) {
      e.preventDefault();

      $(this).parents('.callback-form').addClass('callback-form-with-content');
    })

    // Faq list answers visible toggle
    // -------------------------------
    .on('click.faqListAnswer', '.faq-list > div > div > div:first-child', function(e) {
      e.preventDefault();

      var $self = $(this).parent();

      if (!$self.hasClass('active'))
        $self.addClass('active').children('div + div').slideDown(200);
      else
        $self.removeClass('active').children('div + div').slideUp(200);
    })

    // Header events
    // -------------
    .on('headerEvents', () => {
      if (VIEWPORT_WIDTH >= 1175) {
        if (!$BODY.hasClass('is-desktop-events')) {
          $BODY
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events');

          // Header menu desktop events
          // --------------------------
          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');

          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          $HEADER_SERVICE_MENU
            .find('li').removeClass('is-hovered')
            .find('ul').removeAttr('style');

          $HEADER_SERVICE_MENU
            .find('.header-info-item').appendTo($HEADER_INFO_MENU);

          // Fix header submenu popup position
          // ---------------------------------
          /*let headerMenuWidth = $HEADER_SERVICE_MENU.outerWidth();

          $HEADER_SERVICE_MENU.find('> ul > li').each((i, element) => {
            let $item = $(element),
                $submenu = $item.find('> div > ul');

            if ($submenu.length) {
              $submenu.css('max-width', headerMenuWidth);

              let position = headerMenuWidth - $submenu.outerWidth() - $item.position().left;

              if (position < 0)
                $submenu.css('left', position);
            }
          });*/
        }
      }
      else {
        if (!$BODY.hasClass('is-mobile-events')) {
          $BODY
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events');

          // Header menu mobile events
          // -------------------------
          $HEADER_INFO_MENU.children(':not(.icn-menu-custom1)').addClass('header-info-item').appendTo($HEADER_SERVICE_MENU.children('ul:first'));

          $HEADER_SERVICE_MENU
            .find('ul').removeAttr('style');

          $DOCUMENT
            // Close header menu
            .on('click.closeHeaderMenu', e => {
              if (!($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-service-menu-container > ul').length)) {
                e.stopPropagation();

                $MENU_CHECKBOX.prop('checked', false).trigger('change');
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-service-menu-container > ul a, .header-service-menu-container > ul span, .header-service-menu-container > ul .dropmarker', e => {
              e.preventDefault();

              let $self = $(e.currentTarget),
                  $parent = $self.closest('li'),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');

              if ($self.prop('tagName').toUpperCase() === 'A')
                location.href = $self.prop('href');
              else if (isWithDropdown) {
                if (isOnHover)
                  $parent.removeClass('is-hovered').find('> ul').slideUp(300);
                else
                  $parent.addClass('is-hovered').find('> ul').slideDown(300);
              }
            });
        }
      }
    });


  // Header service menu
  // -------------------
  /*if( window.IS_TOUCH_DEVICE )
    $DOCUMENT
      .on('touchstart', function() {
        $('.header-service-menu-container > ul > li > ul > li').removeClass('is-hovered');
      })
      .on('touchstart.header-service-menu-span', '.header-service-menu-container > ul > li > ul > li', function() {
        $(this).closest('li').addClass('is-hovered').siblings().removeClass('is-hovered');
      });*/


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    margin: 0,

    infobar: false,
    buttons: [
      'close',
      false,
      false,
      false
    ],

    smallBtn: false,

    protect : true,

    focusElement: '.js-autofocus-inp'
  });

  $('[data-fancybox]').fancybox();


  // Slick sliders
  // -------------
  var slickSettings = {
    autoplay: !true,
    autoplaySpeed: 6000,
    prevArrow: '<div class="slick-prev"></div>',
    nextArrow: '<div class="slick-next"></div>',
    customPaging: function() { return $('<div />'); },
    draggable: false
  };

  // Main rotator
  $('.main-rotator-container').slick($.extend({}, slickSettings, {
    centerMode: true,
    centerPadding: '0px',
    fade: true,
    dots: true,
    slide: '.main-rotator-slide-container',
    appendArrows: '#main-rotator-controls > div',
    appendDots: '#main-rotator-dots > div > div',
    responsive: [
      {
        breakpoint: DESKTOP_WIDTH,
        settings: {
          autoplay: false,
          adaptiveHeight: true
        }
      }
    ]
  }));

  // Main gallery rotator
  $('.main-gallery-rotator').each(function() {
    var $self = $(this);

    $self
      /*.on('beforeChange', function(e, slick, currentSlide, nextSlide) {
        let direction = 0;

        if ((currentSlide === 0) && (nextSlide === (slick.slideCount - 1)))
          direction = -1;

        if ((currentSlide === (slick.slideCount - 1)) && (nextSlide === 0))
          direction = 1;

        if (direction !== 0) {
          slick._activeClonedSlide = (nextSlide + (slick.slideCount * direction));
          slick.$slider.find('.main-gallery-image-container.slick-cloned[data-slick-index="'.concat(slick._activeClonedSlide, '"]')).addClass('slick-active-fix');
        }
      })
      .on('afterChange', function(e, slick, currentSlide) {
        slick.$slider.find('.main-gallery-image-container.slick-cloned[data-slick-index="'.concat(slick._activeClonedSlide, '"]')).removeClass('slick-active-fix');
      })*/
      .slick($.extend({}, slickSettings, {
        dots: true,
        slide: '.main-gallery-image-container',
        prevArrow: '<div class="slick-prev"><div class="button-style"></div></div>',
        nextArrow: '<div class="slick-next"><div class="button-style"></div></div>',
        appendArrows: $self.find('.main-gallery-controls'),
        appendDots: $self.find('.main-gallery-dots > div > div')
      }));
  });


  // Portfolio filter
  // ----------------
  if( $('.portfolio-page-block-container').length ) {
    var $portfolioGrid = $('.portfolio-page-block-container .portfolio-items').isotope({
          itemSelector: '.list-view-item',
          layoutMode: 'fitRows',
          percentPosition: true,
          transitionDuration: '0.5s',
          containerStyle: null
        }),
        $links = $('.portfolio-page-block-container .quick-link-list a');

    $links.on('click.portfolio-filter', function(e) {
      e.preventDefault();

      location.hash = encodeURIComponent( $(this).data('filterValue') );
    });

    $WINDOW.on('hashchange', function(e) {
      var filterValue = decodeURIComponent( location.hash.slice(1) );

      if( filterValue.length ) {
        $links.removeClass('active').filter('[data-filter-value="' + filterValue + '"]').addClass('active');

        if( filterValue === 'all' ) {
          $portfolioGrid.isotope({ filter: '*'});
        } else {
          $portfolioGrid.isotope({ filter: '[data-filter-section="' + filterValue  + '"]'});
        }
      }
    });
  }


  // Callbacks
  // ---------
  /*CALLBACKS['reachGoalPhoneOrder'] = function() {
    if( typeof yaCounter46020042 === 'object' )
      yaCounter46020042.reachGoal('phone_order');
  };*/

})();